import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: absolute;
  top: 280px;
  left: 0px;
  width: 280px;
  height: auto;

  left: 30px;

  display: flex;

  transform: rotate(-90deg);
  transform-origin: top left;
  pointer-events: auto;

  margin-top: -140px;

  p,
  a {
    padding-right: 15px;
    font-size: 1rem;
    color: #000;
  }
  p {
    padding-left: 10px;
  }

  a:hover {
    color: #e30015;
  }

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    display: none;
  }
`;

class Social extends React.Component {
  render() {
    const { infotext, socialmenu } = this.props.data;

    return (
      <Wrapper>
        <p>{infotext}</p>
        {socialmenu &&
          socialmenu.map((social, index) => (
            <a
              key={index}
              href={social.buttonurl}
              target="_blank"
              rel="noopener noreferrer">
              {social.buttonlabel}
            </a>
          ))}
      </Wrapper>
    );
  }
}

export default Social;
