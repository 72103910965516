import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: inline-block;
  cursor: pointer;
  pointer-events: auto;

  span {
    display: inline-block;

    /* */
    -webkit-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1); /* older webkit */
    -webkit-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -moz-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -o-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275); /* easeOutBack */

    -webkit-transition-timing-function: cubic-bezier(
      0.175,
      0.885,
      0.32,
      1
    ); /* older webkit */
    -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition-timing-function: cubic-bezier(
      0.175,
      0.885,
      0.32,
      1.275
    ); /* easeOutBack */
    /**/

    font-size: 1.625rem;
    padding-left: 5px;
  }

  a {
    &:hover {
      span {
        transform: translateX(10px);
        color: #e30015;
      }
    }
  }
`;

class Button extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { label, url } = this.props;

    let tar = "_blank";

    if (url.charAt(0) === "/") {
      tar = "_self";
    }

    return (
      <Wrapper
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}>
        <a href={url} target={tar} rel="noopener noreferrer">
          {label} <span>→</span>
        </a>
      </Wrapper>
    );
  }
}

export default Button;
