import React from "react";
import styled from "styled-components";
import ButtonRound from "../utils/buttonround";
import SectionTitle from "../sectiontitle";

const Wrapper = styled.div`
  margin-bottom: 170px;
  padding-top: 35px;
  margin-top: 12px;

  h2 {
    padding-top: 110px;
    padding-right: 80px;
  }

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    margin-bottom: 130px;
  }
`;

const Content = styled.div`
  padding-left: 214px;
  padding-right: 210px;
  padding-top: 64px;

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    padding: 0px;

    padding-left: 30px;
    padding-right: 30px;
  }
`;

const BtnWrapper = styled.div`
  text-align: center;
  width: 100%;
`;

const Grid = styled.div`
  height: 100%;
  padding-top: 60px;
  padding-bottom: 80px;

  display: grid;
  grid-template-columns: 70% 30%;
  grid-template-rows: auto;
  column-gap: 20px;

  align-items: start;
  justify-items: start;

  span {
    padding-left: 20px;
  }
`;

const SplitBoxLeft = styled.div`
  a {
    :hover {
      color: #000;
    }
  }
`;

const Box = styled.div`
  display: flex;

  a {
    color: #000;

    :hover {
      color: #e30015;
    }
  }

  p {
    width: 18px;
  }

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    span {
      font-size: 1.125rem !important; /* 18 */
      line-height: 1.85rem;
    }
  }
`;

const SplitBoxRight = styled.div`
  p {
    margin-bottom: 35px;
  }
`;

const Headline = styled.p`
  color: #e30015;
`;

class Contact extends React.Component {
  render() {
    const {
      sectiontitel,
      headline,
      telefon,
      fax,
      mail,
      richtext,
      buttonlabel,
      buttonlabelshort,
      eMail,
    } = this.props.data;

    const { compid, compnum } = this.props;

    return (
      <Wrapper>
        {compid === 1 && <SectionTitle title={sectiontitel} number={compnum} />}

        <Content>
          <Grid>
            <SplitBoxLeft>
              <Headline>{headline}</Headline>

              <p dangerouslySetInnerHTML={{ __html: richtext }} />

              <Box>
                <p>T</p>
                <span dangerouslySetInnerHTML={{ __html: telefon }} />
              </Box>

              <Box>
                <p>F</p>
                <span dangerouslySetInnerHTML={{ __html: fax }} />
              </Box>

              <Box>
                <p>W</p>
                <span dangerouslySetInnerHTML={{ __html: mail }} />
              </Box>
            </SplitBoxLeft>

            <SplitBoxRight></SplitBoxRight>
          </Grid>
        </Content>
        <BtnWrapper>
          <ButtonRound
            label={buttonlabel}
            labelshort={buttonlabelshort}
            url={eMail}
          />
        </BtnWrapper>
      </Wrapper>
    );
  }
}

export default Contact;
