import React from "react";
import styled from "styled-components";
import Fade from "react-reveal/Fade";

import { useMediaQuery } from "react-responsive";

const Wrapper = styled.div`
  padding-top: 25px;
  padding-bottom: 50px;

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 45px;
  }

  a {
    color: #000;

    :hover {
      color: #e30015;
    }
  }
`;

const Social = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
`;

const Grid = styled.div`
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  align-items: center;

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    grid-template-columns: 100%;
    grid-template-rows: auto;
  }
`;

const GridItem = styled.div`
  text-align: right;
  align-self: end;

  &.left {
    justify-self: start;
  }

  &.right {
  }

  p {
    color: #000;
  }
  @media (max-width: ${(props) => props.theme.mobilesize}) {
    text-align: left;
    align-self: start;
  }
`;

const BtnWrapper = styled.div`
  padding-left: 80px;
  display: inline;

  a {
    :hover {
      color: #e30015;
    }
  }
  @media (max-width: ${(props) => props.theme.mobilesize}) {
    padding-left: 0px;
    display: block;
  }
`;

class Footer extends React.Component {
  render() {
    const { infotext, submenu } = this.props.data;

    const socialmenu = this.props.dataSocial.socialmenu;
    const socialtext = this.props.dataSocial.infotext;

    const Mobile = ({ children }) => {
      const isMobile = useMediaQuery({ maxWidth: 1200 });
      return isMobile ? children : null;
    };

    const Desktop = ({ children }) => {
      const isMobile = useMediaQuery({ minWidth: 1200 });
      return isMobile ? children : null;
    };

    return (
      <Wrapper>
        <Fade bottom distance={"20px"} duration={500} delay={100}>
          <Mobile>
            <Grid>
              <GridItem className="left">
                {submenu &&
                  submenu.map((footer, index) => (
                    <BtnWrapper key={index}>
                      <a
                        href={footer.buttonurl}
                        target="_blank"
                        rel="noopener noreferrer">
                        {footer.buttonlabel}
                      </a>
                    </BtnWrapper>
                  ))}
              </GridItem>
              <GridItem className="right">
                <Social>
                  <p>{socialtext}</p>
                  {socialmenu &&
                    socialmenu.map((social, index) => (
                      <BtnWrapper key={index}>
                        <a
                          key={index}
                          href={social.buttonurl}
                          target="_blank"
                          rel="noopener noreferrer">
                          {social.buttonlabel}
                        </a>
                      </BtnWrapper>
                    ))}
                </Social>
                <p>{infotext}</p>
              </GridItem>
            </Grid>
          </Mobile>
          <Desktop>
            <Grid>
              <GridItem className="left">
                <p>{infotext}</p>
              </GridItem>
              <GridItem className="right">
                {submenu &&
                  submenu.map((footer, index) => (
                    <BtnWrapper key={index}>
                      <a
                        href={footer.buttonurl}
                        target="_blank"
                        rel="noopener noreferrer">
                        {footer.buttonlabel}
                      </a>
                    </BtnWrapper>
                  ))}
              </GridItem>
            </Grid>
          </Desktop>
        </Fade>
      </Wrapper>
    );
  }
}

export default Footer;
