import React from "react";
import styled from "styled-components";
import gsap from "gsap";

const Wrapper = styled.div`
  position: absolute;
  width: 52px;
  height: 52px;
  overflow: hidden;

  transform: translate(-14px, -14px);
`;

const Background = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  transform: translate(14px, 14px);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgba(224, 13, 32, 0.7);

  &:after {
    position: absolute;
    content: "";
    top: 4px;
    left: 4px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: rgba(224, 13, 32, 1);
  }
`;

class RedDot extends React.Component {
  componentDidMount() {
    gsap.to(".rd", {
      scale: 1,
      filter: "blur(2px)",
      duration: 0,
    });

    let tween = gsap.to(".rd", {
      scale: 1.5,
      filter: "blur(3px)",
      duration: 2,
      ease: "power1.inOut",
      repeat: -1,
      delay: 1,
      yoyo: true,
    });

    setTimeout(() => {
      tween.play();
    }, 1000);
  }

  render() {
    return (
      <Wrapper>
        <Background className="rd"></Background>
      </Wrapper>
    );
  }
}

export default RedDot;
