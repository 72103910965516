import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 740px;
  height: 740px;
  transform: translate(-50px, -50px);
`;

const RedDot = styled.div`
  position: absolute;
  top: 50px;
  left: 50px;
  width: 640px;
  height: 640px;
  border-radius: 640px;
  background-color: rgba(224, 13, 32, 0.7);

  filter: blur(1.6rem);

  &:after {
    position: absolute;
    content: "";
    top: 50px;
    left: 50px;
    width: 540px;
    height: 540px;
    border-radius: 540px;
    background-color: rgba(224, 13, 32, 0.9);
  }

  opacity: 0;
  transform: scale(1);

  animation-name: aniKey;
  animation-duration: 1.1s;
  animation-iteration-count: 1;
  animation-delay: 1.4s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;

  @keyframes aniKey {
    0% {
      opacity: 0;
      transform: scale(0.8);
    }
    50% {
      opacity: 1;
      transform: scale(1.1);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

class RedDotAniBig extends React.Component {
  render() {
    return (
      <Wrapper>
        <RedDot></RedDot>
      </Wrapper>
    );
  }
}

export default RedDotAniBig;
