import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  left: 50px;
  width: 240px;
  height: 240px;

  -webkit-backface-visibility: hidden;
  transform: scale(1) rotate(12deg);
  transition: all 0.4s ease-in-out;

  pointer-events: auto;
  :hover {
    transform: scale(1.1) rotate(8deg);
  }

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    left: 80px;
  }
`;

const Copy = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  justify-items: center;
  align-items: center;

  p {
    font-size: 1.5rem; /* 24 */
    line-height: 1.875rem;
    1.5rem;
    font-weight: 300;
    color: #fff;
    text-align: center;
    user-select: none;
  }

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    p {
      line-height: 1.5rem !important;
    }
  }
`;

const RedDot = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 240px;
  height: 240px;
  border-radius: 50%;
  background-color: rgba(224, 13, 32, 0.7);
  filter: blur(1.3rem);

  &:after {
    position: absolute;
    content: "";
    top: 30px;
    left: 30px;
    width: 180px;
    height: 180px;
    border-radius: 50%;
    background-color: rgba(224, 13, 32, 0.9);
  }



  @media (max-width: ${(props) => props.theme.mobilesize}) {
    transform: scale(.8); 
    filter: blur(1rem);

  }


  }
`;

class Stoerer extends React.Component {
  render() {
    const copy = this.props.copy;

    return (
      <Wrapper>
        <RedDot />
        <Copy>
          <p dangerouslySetInnerHTML={{ __html: copy }} />
        </Copy>
      </Wrapper>
    );
  }
}

export default Stoerer;
