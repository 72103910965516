import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  margin-bottom: 150px;
  margin-bottom: 174px;
  margin-left: 215px;
  margin-right: 215px;
  margin-top: 30px;

  .red {
    color: #e3000f;
  }
  span {
    font-size: 2.125rem;
  }
  @media (max-width: ${(props) => props.theme.mobilesize}) {
    margin: 0px;
    margin-bottom: 100px;
    h2,
    span {
      font-size: 1.625rem;
      line-height: 2.2rem;
    }

    h2 {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
`;

const BtnWrapper = styled.div`
  text-align: center;
  width: 100%;
  margin-top: 150px;

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    margin-top: 75px;
  }
`;

const Button = styled.div`
  display: inline-block;
  cursor: pointer;

  border-radius: 50px;
  border: 2px solid #e30015;

  padding-left: 35px;
  padding-right: 55px;
  padding-top: 16px;
  padding-bottom: 14px;
  color: #e30015;

  a {
    font-size: 1.5rem;
    font-weight: 300;
  }

  span {
    position: absolute;
    font-size: 1.625rem;
    line-height: 1.95rem;
    padding-left: 10px;
    padding-top: 1px;
    transition: all ease 0.4s;
  }

  &:hover {
    span {
      padding-left: 20px;
      color: #e30015;
    }
  }

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    a,
    span {
      font-size: 1.625rem;
      line-height: 1.85rem;
    }

    span {
      position: relative;
    }

    padding-left: 30px;
    padding-right: 30px;

    &:hover {
      span {
        padding-left: 5px;
      }
    }
  }
`;

const Btn = styled.div`
  display: inline-table;
  p {
    display: inline;
  }
`;

class CompIntrotext extends React.Component {
  render() {
    const { headline, copytext, buttonlabel, buttonurl } = this.props.data;

    return (
      <Wrapper>
        <>
          <h2>
            <span className="red">{headline} </span>
            {copytext}
          </h2>

          {buttonlabel !== null && (
            <BtnWrapper>
              <Button>
                <a href={buttonurl} target="_blank" rel="noopener noreferrer">
                  <Btn>
                    <p>{buttonlabel}</p>
                    <span>→</span>
                  </Btn>
                </a>
              </Button>
            </BtnWrapper>
          )}
        </>
      </Wrapper>
    );
  }
}

export default CompIntrotext;
