import React from "react";
import styled from "styled-components";
import SectionTitle from "../sectiontitle";

import { useMediaQuery } from "react-responsive";

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  margin-bottom: 74px;

  padding-top: 10px;
  padding-bottom: 150px;
  /*border: 1px solid #ff00ee;*/

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    margin-bottom: 50px;
    padding-bottom: 50px;
  }
`;

const Content = styled.div`
  padding-left: 210px;
  padding-right: 210px;

  padding-top: 124px;

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    padding-left: 0px;
    padding-right: 0px;
    padding-left: 30px;
    padding-right: 30px;

    padding-top: 50px;
  }
`;

const Headline = styled.h2`
  font-family: "NEXTBook-Bold";
  color: #e30015;
  font-size: 1.25rem; /* 20 */
  letter-spacing: 0rem;
  line-height: 1.2rem;

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    font-size: 1.125rem !important;
  }
`;

const Grid = styled.div`
  height: 100%;

  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto auto;
  grid-auto-flow: column dense;
  align-items: start;
  justify-items: start;

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-auto-flow: row;
  }
`;

const SplitBox = styled.div`
  padding: 5px;

  ul {
    padding-left: 18px;
  }
  margin-bottom: 0px;

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    padding: 0px;
    margin-bottom: 0px;
  }
`;

const ItemImage = styled.div`
  position: absolute;
  bottom: -150px;
  right: 0px;
  width: 50%;
  height: 50%;

  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    position: relative;
    bottom: auto;
    left: 30px;

    width: 75%;
    height: auto;
    margin-top: 25px;
  }
`;

class CompAblauf extends React.Component {
  render() {
    const { sectiontitel, listitem, image, imagemobile } = this.props.data;

    const { compid, compnum } = this.props;

    const renderImage = process.env.REACT_APP_IMG_URL + image[0].url;
    const renderImageMobile =
      process.env.REACT_APP_IMG_URL + imagemobile[0].url;

    const Mobile = ({ children }) => {
      const isMobile = useMediaQuery({ maxWidth: 1200 });
      return isMobile ? children : null;
    };

    const Desktop = ({ children }) => {
      const isMobile = useMediaQuery({ minWidth: 1200 });
      return isMobile ? children : null;
    };

    return (
      <Wrapper>
        {compid === 1 && <SectionTitle title={sectiontitel} number={compnum} />}

        <Content>
          <Grid>
            {listitem &&
              listitem.map((list, index) => (
                <SplitBox key={index}>
                  <Headline>{list.itemtitle}</Headline>
                  <p dangerouslySetInnerHTML={{ __html: list.itemlist }} />
                </SplitBox>
              ))}
          </Grid>
        </Content>
        <ItemImage>
          <Mobile>
            <img src={renderImageMobile} />
          </Mobile>

          <Desktop>
            <img src={renderImage} />
          </Desktop>
        </ItemImage>
      </Wrapper>
    );
  }
}

export default CompAblauf;
