import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: absolute;
  top: 280px;
  left: 0px;
  width: 160px;
  height: auto;

  display: flex;

  margin-top: 0px;

  transform: rotate(-90deg);
  transform-origin: top left;
  pointer-events: auto;

  p,
  a {
    padding-right: 15px;
    font-size: 1rem;
    color: #000;
  }
  p {
    padding-left: 10px;
  }

  a:hover {
    color: #e30015;
  }

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    display: none;
  }
`;

class BackTop extends React.Component {
  render() {
    return (
      <Wrapper>
        <a href="/" target="_self" rel="noopener noreferrer">
          Zurück zur Startseite
        </a>
      </Wrapper>
    );
  }
}

export default BackTop;
