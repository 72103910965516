import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 20px;
`;

class CompText extends React.Component {
  render() {
    const { headline, copytext } = this.props.data;

    return (
      <Wrapper>
        <h1>CompText</h1>
        <h2>{headline}</h2>
        <p>{copytext}</p>
      </Wrapper>
    );
  }
}

export default CompText;
