import React from "react";
import styled from "styled-components";

const Title = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 35px 180px auto;
  grid-template-rows: auto;

  h3 {
    color: #e30015;
    /* font-family: "NEXTBook-Light"; */
    font-weight: 500;

    &.blk {
      color: #000;
    }
  }
  span {
    margin-right: 12px;
    height: 9px;
    border-bottom: 1px solid #e30015;
  }

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    grid-template-columns: 35px 90px auto;
    padding-left: 30px;
    padding-right: 30px;

    h3 {
      letter-spacing: 0;
    }
  }
`;

class SectionTitle extends React.Component {
  render() {
    const { title, number } = this.props;

    return (
      <Title>
        <h3>0{number}</h3>
        <span></span>
        <h3 className="blk">{title}</h3>
      </Title>
    );
  }
}

export default SectionTitle;
