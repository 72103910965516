import React from "react";
import styled from "styled-components";
import Button from "../utils/button";
import SectionTitle from "../sectiontitle";

import DotsAnimeSplit from "../utils/DotsAnimeSplit";
import DotsAnimeStill from "../utils/DotsAnimeStill";

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: 10px;
  padding-bottom: 100px;

  h2 {
    color: #e30015;
    margin-top: 120px;
    padding-right: 80px;
  }
  @media (max-width: ${(props) => props.theme.mobilesize}) {
    h2 {
      padding: 0px;
    }
    padding-bottom: 105px;
  }
`;

const DotsWrapper = styled.div`
  position: absolute;
  top: 60px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  @media (max-width: ${(props) => props.theme.mobilesize}) {
  }
`;

const Content = styled.div`
  position: relative;
  padding-left: 210px;
  padding-right: 210px;
  padding-top: 8px;

  pointer-events: none;
  h2 {
    background-color: #fff;
    padding-left: 5px;
    padding-right: 100px;
  }
  @media (max-width: ${(props) => props.theme.mobilesize}) {
    padding: 0px;
    padding-left: 25px;
    padding-right: 30px;
    padding-top: 10px;
    h2 {
      padding: 0px;
    }
  }
`;

const Grid = styled.div`
  height: 100%;
  padding-top: 50px;

  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;

  align-items: start;
  justify-items: start;
  @media (max-width: ${(props) => props.theme.mobilesize}) {
    grid-template-columns: 100%;
    grid-template-rows: auto;
  }
`;

const SplitBoxLeft = styled.div`
  background-color: #fff;
  padding: 10px;
  padding-top: 5px;
  margin-right: 12px;

  .blue {
    color: #ff0000;
  }
  @media (max-width: ${(props) => props.theme.mobilesize}) {
    padding: 5px;
    margin: 0px;
    margin-bottom: 20px;
  }
`;

const SplitBoxRight = styled.div`
  background-color: #fff;
  padding: 10px;
  padding-top: 5px;
  margin-left: 12px;
  p {
    margin-bottom: 35px;
  }
  @media (max-width: ${(props) => props.theme.mobilesize}) {
    padding: 5px;
    margin: 0px;
  }
`;

class CompSplitCopy extends React.Component {
  render() {
    const {
      sectiontitel,
      headline,
      copytextleft,
      copytextright,
      buttonlabel,
      buttonurl,
      style,
    } = this.props.data;

    const { compid, compnum } = this.props;

    return (
      <Wrapper>
        {style === true && (
          <>
            <DotsWrapper>
              <DotsAnimeSplit id={compid} />
            </DotsWrapper>
          </>
        )}

        {compid === 1 && <SectionTitle title={sectiontitel} number={compnum} />}

        <Content>
          <h2>{headline}</h2>

          <Grid>
            <SplitBoxLeft>
              <p dangerouslySetInnerHTML={{ __html: copytextleft }} />
            </SplitBoxLeft>

            <SplitBoxRight>
              <p dangerouslySetInnerHTML={{ __html: copytextright }} />
              <Button label={buttonlabel} url={buttonurl}></Button>
            </SplitBoxRight>
          </Grid>
        </Content>
      </Wrapper>
    );
  }
}

export default CompSplitCopy;
