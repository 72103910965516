import React from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

const Wrapper = styled.div`
  display: inline-block;
  cursor: pointer;

  border-radius: 50px;
  border: 2px solid #e30015;

  padding-left: 35px;
  padding-right: 55px;
  padding-top: 16px;
  padding-bottom: 14px;
  color: #e30015;

  a {
    font-size: 1.5rem;
    font-weight: 300;
  }

  span {
    position: absolute;
    font-size: 1.625rem;
    line-height: 1.95rem;
    padding-left: 10px;
    padding-top: 1px;
    transition: all ease 0.4s;
  }

  &:hover {
    span {
      padding-left: 20px;
      color: #e30015;
    }
  }

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    a,
    span {
      font-size: 1.625rem;
      line-height: 1.85rem;
    }

    span {
      position: relative;
    }

    padding-left: 40px;
    padding-right: 40px;

    &:hover {
      span {
        padding-left: 5px;
      }
    }
  }
`;

const Btn = styled.div`
  display: inline-table;
  p {
    display: inline;
  }
`;

class ButtonRound extends React.Component {
  render() {
    const { label, labelshort, url } = this.props;
    const Mobile = ({ children }) => {
      const isMobile = useMediaQuery({ maxWidth: 1200 });
      return isMobile ? children : null;
    };

    const Desktop = ({ children }) => {
      const isMobile = useMediaQuery({ minWidth: 1200 });
      return isMobile ? children : null;
    };

    return (
      <Wrapper>
        <a href={"mailto:" + url} rel="noopener noreferrer">
          <Mobile>
            <Btn>
              <p>{labelshort}</p> <span>→</span>
            </Btn>
          </Mobile>
          <Desktop>
            <Btn>
              <p>{label}</p> <span>→</span>
            </Btn>
          </Desktop>
        </a>
      </Wrapper>
    );
  }
}

export default ButtonRound;
