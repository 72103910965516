import React from "react";
import styled from "styled-components";
import SectionTitle from "../sectiontitle";
import DotsAnime from "../utils/DotsAnime";

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  margin-bottom: 100px;
  padding-top: 10px;
  padding-bottom: 110px;
  /*border: 1px solid #ff00ee;*/

  h2 {
    color: #e30015;
    margin-top: 120px;
    padding-right: 80px;
  }

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    margin: 0;
    padding: 0;

    padding-bottom: 75px;
    margin-bottom: 100px;
  }
`;

const DotsWrapper = styled.div`
  position: absolute;
  top: 60px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    top: 50px;
  }
`;

const Content = styled.div`
  padding-left: 210px;
  padding-right: 210px;
  position: relative;
  pointer-events: none;

  h2 {
    background-color: #fff;
    padding-left: 5px;
    padding-right: 100px;
  }

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    padding-left: 25px;
    padding-right: 30px;
  }
`;

const Grid = styled.div`
  height: 100%;
  padding-top: 128px;

  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;

  align-items: start;
  justify-items: start;

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    grid-template-columns: 100%;
    padding-top: 108px;
  }
`;

const SplitBoxLeft = styled.div`
  background-color: #fff;

  padding: 10px;
  padding-top: 5px;
  margin-right: 12px;

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    padding: 5px;
  }
`;

const SplitBoxRight = styled.div`
  background-color: #fff;

  padding: 10px;
  padding-top: 5px;
  margin-left: 12px;

  p {
    margin-bottom: 25px;
  }

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    padding: 5px;
    margin: 0px;
    padding-top: 25px;
  }
`;

class CompPreise extends React.Component {
  render() {
    const { sectiontitel, copytextleft, copytextright } = this.props.data;

    const { compid, compnum } = this.props;

    return (
      <Wrapper>
        <DotsWrapper>
          <DotsAnime id={"wp" + compid} />
        </DotsWrapper>

        {compid === 1 && <SectionTitle title={sectiontitel} number={compnum} />}

        <Content>
          <Grid>
            <SplitBoxLeft>
              <p dangerouslySetInnerHTML={{ __html: copytextleft }} />
            </SplitBoxLeft>

            <SplitBoxRight>
              <p dangerouslySetInnerHTML={{ __html: copytextright }} />
            </SplitBoxRight>
          </Grid>
        </Content>
      </Wrapper>
    );
  }
}

export default CompPreise;
