import React from "react";
import styled from "styled-components";
import anime from "animejs/lib/anime.es.js";

const Wrapper = styled.div`
  position: absolute;
  top: -2px;
  left: -10px;
  width: 1460px;
  height: 1500px;

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    left: 0px;
  }

  .staggering-grid {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: left;
    text-align: center;
    width: 100%;
    height: 100%;
  }

  .grid {
    display: flex;
    flex-wrap: wrap;
  }

  .dotsplit {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 24px;
    height: 24px;

    border-radius: 50%;
    background: #e30015;
    transform: scale(0.1);
  }

  .dotwrapper {
    position: relative;
    width: 24px;
    height: 24px;
    margin: 1px;
    cursor: pointer;

    &:before {
      position: absolute;
      top: -2px;
      left: -2px;
      width: 26px;
      height: 26px;
      content: "";
    }
  }
`;

const grid = [56, 50];
const numDots = grid[0] * grid[1];
var count2 = 0;

class DotsAnimeSplit extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleEnter = this.handleEnter.bind(this);
  }

  componentDidMount() {
    const tar = ".staggering-grid-" + this.props.id + " .dotsplit";
    anime.timeline({}).add({
      targets: tar,
      keyframes: [
        {
          scale: 0.1,
          duration: 0,
        },
      ],
      delay: anime.stagger(0, { grid: grid, from: 0 }),
    });
  }

  reset() {
    count2 = 0;
  }

  playAnimation(id) {
    const tar = ".staggering-grid-" + this.props.id + " .dotsplit";

    if (count2 === 0) {
      count2++;
      setTimeout(this.reset, 2000);

      anime
        .timeline({
          easing: "easeInOutQuad",
        })
        .add({
          targets: tar,
          keyframes: [
            {
              translateX: anime.stagger("-2px", {
                grid: grid,
                from: id,
                axis: "x",
              }),
              translateY: anime.stagger("-2px", {
                grid: grid,
                from: id,
                axis: "y",
              }),
              scale: 0.25,
            },
            {
              translateX: 0,
              translateY: 0,
              scale: 0.1,
              duration: 300,
            },
          ],
          delay: anime.stagger(65, { grid: grid, from: id }),
        });
    }
  }

  handleClick(id) {
    this.playAnimation(id);
  }

  handleEnter() {
    this.playAnimation(254);
  }

  render() {
    const arr = new Array(numDots).fill(0);
    const { id, force } = this.props;

    return (
      <Wrapper>
        <div className={`staggering-grid staggering-grid-${id}`}>
          <div className="grid">
            {arr.map((item, index) => {
              return (
                <div
                  key={index}
                  id={index}
                  className="dotwrapper"
                  onClick={(e) => this.handleClick(index)}>
                  <span key={index} id={index} className="dotsplit"></span>
                </div>
              );
            }, this)}
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default DotsAnimeSplit;
