import React from "react";
import ReactDOM from "react-dom";

import "./fonts/NEXTBook-Regular.css";
import "./fonts/NEXTBook-Light.css";
import "./fonts/NEXTBook-Bold.css";

import "./index.css";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { ApolloProvider } from "@apollo/react-hooks";
import { setContext } from "apollo-link-context";
import { ProgressiveFragmentMatcher } from "apollo-progressive-fragment-matcher";

const fragmentMatcher = new ProgressiveFragmentMatcher();

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_CRAFT_URL,
});

const authLink = setContext((_, { headers }) => {
  const token = process.env.REACT_APP_CRAFT_TOKEN;
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({ fragmentMatcher }),
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
