import React from "react";
import styled from "styled-components";
import anime from "animejs/lib/anime.es.js";

const Wrapper = styled.div`
  position: absolute;
  top: -2px;
  left: -10px;
  width: 1460px;
  height: 1500px;

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    left: 0px;
  }

  .staggering-grid-still {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: left;
    text-align: center;
    width: 100%;
    height: 100%;
  }

  .grid-still {
    display: flex;
    flex-wrap: wrap;
  }

  .dot-still {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 24px;
    height: 24px;

    border-radius: 50%;
    background: #e30015;
    transform: scale(0.1);
  }

  .dotwrapper-still {
    position: relative;
    width: 24px;
    height: 24px;
    margin: 1px;
    cursor: pointer;

    &:before {
      position: absolute;
      top: -2px;
      left: -2px;
      width: 26px;
      height: 26px;
      content: "";
    }
  }
`;

const grid = [56, 50];
const numDots = grid[0] * grid[1];

class DotsAnimeStill extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const tar = ".staggering-grid-" + this.props.id + " .dot-still";
    anime.timeline({}).add({
      targets: tar,
      keyframes: [
        {
          scale: 0.1,
          duration: 0,
        },
      ],
      delay: anime.stagger(0, { grid: grid, from: 0 }),
    });
  }

  render() {
    const arr = new Array(numDots).fill(0);
    const id = this.props.id;

    return (
      <Wrapper>
        <div className={`staggering-grid-still staggering-grid-${id}`}>
          <div className="grid-still">
            {arr.map((item, index) => {
              return (
                <div key={index} id={index} className="dotwrapper-still">
                  <span key={index} id={index} className="dot-still"></span>
                </div>
              );
            }, this)}
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default DotsAnimeStill;
