import React from "react";
import styled from "styled-components";
import gsap from "gsap";

const Wrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
`;

const RedDot = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: rgba(224, 13, 32, 1);
  filter: blur(0.2rem);
  transform: scale(0.6);
  opacity: 0;
`;

class RedDotAni extends React.Component {
  componentDidMount() {
    setTimeout(this.anim, 1000);
  }

  anim() {
    gsap.to(".rda", {
      opacity: 1,
      duration: 1.5,
      ease: "power1.inOut",
    });

    let tween2 = gsap.to(".rda", {
      scale: 0.8,
      filter: "blur(4px)",
      duration: 2.5,
      ease: "power1.inOut",
      repeat: -1,
      yoyo: true,
    });

    tween2.play();
  }

  render() {
    return (
      <Wrapper>
        <RedDot className="rda" />
      </Wrapper>
    );
  }
}

export default RedDotAni;
